h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $bold_font_weight;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 1.5em auto 1em;
    text-align: center;
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2.5em;
}

h3 {
    font-size: 2em;
}

h4 {
    font-size: 1.5em;
}

h5 {
    font-size: 1.25em
}

h6 {
    font-size: 1em;
}