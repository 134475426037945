@import "variables";
@import "grid";
@import "headings";
@import "logo";
@import "navbar";
@import "header";
@import "clock";

* {
    font-family: $font_family;
    box-sizing: border-box;
    scroll-behavior: smooth;
    line-height: 1.5;
}

html,
body {
    height: 100%;
    font-size: $base_font_size;
    padding: 0;
    margin: 0;
    background-color: black;
    color: #fff;

    a {
        color: rgb(255, 112, 136);
    }
}

section {
    p {
        margin-bottom: 1.5em;
    }

    ol {
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 1.5em;
        }
    }
}

#faq {
    summary {
        h3 {
            display: inline;
            text-transform: none;
            font-size: 1em;
            letter-spacing: initial;
        }

        margin: $spacer/2 0;
    }
}

#prizes {
    >ul {
        h3 {
            text-transform: none;
            text-align: left;
            font-size: 1em;
            letter-spacing: initial;
        }
    }
}

#sponsors {
    ul.sponsor-list {
        @include grid-container();
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            @include cols(12);
            @include cols(6, 20);
            @include cols(4, 40);
            @include cols(3, 60);

            margin: $spacer/2 0;

            img {
                width: 80%;
                height: auto;
            }
        }
    }
}

table {
    border-collapse: collapse;
    width: 100%;

    tr {
        padding: 1em;
    }

    &,
    td {
        border: 2px solid grey;
        padding: 1em;
    }
}

::selection {
    background: rebeccapurple;
    color: #fff;
}

.recap-video {
    width: 100%;
    height: 100vh;
}

.notice {
    padding: 5em 2em;
    text-align: center;
    font-size: 1.5em;
}