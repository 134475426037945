.logo {
    position: relative;
    display: inline-block;

    .light {
        font-weight: $light_font_weight;
        vertical-align: middle;
    }

    .bold {
        font-weight: $black_font_weight;
        vertical-align: middle;
    }

    &::after {
        content: attr(data-year);
        position: absolute;
        opacity: 0.25;
        font-size: 1.5em;
        bottom: 0;
        transform: translate(-50%, -5%);
    }
}