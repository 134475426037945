// Grid Variables
$grid-cols: 12;

$container-width-xs: 90%;
$container-width-md: 80%;
$container-width-lg: 70%;
$container-width-xl: 60%;

$breakpoint-md: 40em;
$breakpoint-xs: 60em;
$breakpoint-lg: 80em;

$spacer: 30px;

// Typography

$font_family: urw-din, sans-serif;
$base_font_size: 17px;
$light_font_weight: 200;
$normal_font_weight: 400;
$bold_font_weight: 900;
$black_font_weight: 900;